import request from '@/utils/request'

// export function getArticleList(params){
//     return request({
//         url: '/app/cms/article/paging',
//         method: 'post',
//         needToken: false,
//         data: params
//     })
// }

export function getArticlePaging(data){
    return request({
        url: '/app/cms/article/page',
        method: 'POST',
        data
    })
}

export function getArticleDetail(id){
    return request({
        url: `/app/cms/article/${id}`,
        method: 'get',
        needToken: false
    })
}
