<template>
  <div>
    <div class="am-hd">
      <h2>{{ detail.title }}</h2>
    </div>
    <div class="am-bd">
      <div class="article-words" v-html="detail.content"></div>
    </div>
  </div>
</template>

<script>
import {getArticleDetail} from "@/api/article";

export default {
  name: "View",
  data() {
    return {
      detail:{}
    }
  },
  mounted() {
    this.getDetail()
  },
  computed:{
    webConfig() {
      return this.$store.state.settings.webConfig
    },
  },
  methods:{
    getDetail(){
      getArticleDetail(this.$route.query.id).then(res => {
        this.detail = res.data
        let title = document.title
        document.title = this.detail.title + ' _ ' + title
        this.detail.content = this.parsedHtml(this.detail.content)
        this.detail.content = this.replaceTag(this.detail.content)
      })
    },
    parsedHtml(content) {
      // 解析 HTML 内容中的 <img> 标签，并修改其 src 路径
      const regex = /<img(.*?)src="(.*?)"(.*?)>/g;
      const modifiedHtml = content.replace(regex, (match, p1, p2, p3) => {
        // 在这里可以对原始值 p2 进行逻辑处理
        var tempSrc = p2;
        if(!tempSrc.includes('http')){
          tempSrc = this.staticUrl + '/' + tempSrc
        }
        const modifiedSrc = tempSrc;
        return `<img${p1}src="${modifiedSrc}"${p3}>`;
      });

      return modifiedHtml;
    },
    replaceTag(content){
      let temp = content

      if(this.webConfig.telephoneCustomer){
        temp = temp.replace('#客服电话#', this.webConfig.telephoneCustomer)
      }
      if(this.webConfig.telephoneAfterSales){
        temp = temp.replace('#售后电话#', this.webConfig.telephoneAfterSales)
      }
      if(this.webConfig.returnAddress){
        temp = temp.replace('#退货地址#', this.webConfig.returnAddress)
      }
      if(this.webConfig.returnTelephone){
        temp = temp.replace('#退货电话#', this.webConfig.returnTelephone)
      }
      if(this.webConfig.platformName){
        temp = temp.replace('#平台名称#', this.webConfig.platformName)
      }
      if(this.webConfig.platformShortName){
        temp = temp.replace('#平台简称#', this.webConfig.platformShortName)
      }
      if(this.webConfig.companyName){
        temp = temp.replace('#公司名称#', this.webConfig.companyName)
      }
      if(this.webConfig.companyShortName){
        temp = temp.replace('#公司简称#', this.webConfig.companyShortName)
      }
      return temp
    }
  }
}
</script>

<style>
.bbs_content img{
  width: 910px !important;
}
</style>
